:root {
  --primary-color: #0B8989; /* Định nghĩa một biến CSS */
}

body {
  margin: 0;
  font-family: CustomFont;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Texturina:wght@200;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rowdies:wght@700&display=swap");

@font-face {
  font-family: 'CustomFont';
  /* src: url('./font/SVN-DHF Dexsar Brush.ttf') format('opentype'); */
  /* src: url('./font/._000FMAtlas-Regular.otf') format('opentype'); */
  src: url('./font/BeVietnamPro-Regular.ttf') format('opentype');
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #ffcd43 0%,
    #ccff56 10%,
    #9dff41 20%,
    #4aff53 30%,
    #4affc9 40%,
    #4adbff 50%,
    #4a7aff 60%,
    #714aff 70%,
    #f04aff 80%,
    #ff3b8d 90%,
    #ff3243 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 8s linear infinite;
  display: inline-block;
  font-size: 190px;
  font-family: Rowdies;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(31, 29, 29, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c1c1c1;
  border: 1px solid #555555;
}

/* *:hover::-webkit-scrollbar {
  width: 10px;
} */

.zalo-chat-widget {
  right: 5px !important;
  bottom: 5px !important;
}

.container{
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 5rem;
  padding-right: 5rem;
}

.hide-in-tablet{

}

.hide-in-mobile {
  display: flex;
}

.hide-in-pc {
  display: none;
}


.header-class {
  display:none;
}

.block-mobile{
  display: flex;
}

.overlay{
  background-color: #474747;
  opacity: 0.3;
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: 200;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

.popup-menu {
  position: fixed;
  top: 80px;
  right: 0;
  transform: translateX(100%);
  width: 300px;
  height: 100%;
  background-color: var(--primary-color);
  color: white;
  font-family: CustomFont;
  letter-spacing: 2px;
  transition: transform 0.2s linear;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-right: none;
  box-sizing: border-box;
  z-index: 300;
}

.popup-login {
  width: 60%;
  margin: auto;
  height: 80%;
  background-color: wheat;
  border-radius: 22px;
  padding: 20px;
  position: relative;
  min-height: 522px;
}

.popup-menu.open {
  transform: translateX(0%);
}

@media only screen and (max-width: 770px) {
    .header-class{
      display:block;
    }
    .hide-in-mobile {
      display: none !important;
    }
    .container{
      padding: 20px;
    }
    .hide-in-pc {
      display: flex;
    }
    .block-mobile{
      display: block;
    }
    .popup-login {
      width: 95%;
      min-width: 380px;
      margin: auto;
      height: 95%;
      min-height: 522px;
      background-color: wheat;
      border-radius: 22px;
      padding: 20px;
      position: relative;
    }
}

@media only screen and (max-width:1050px) and (min-width:770px){
  .header-class{
    display:block;
  }
  .popup-login {
    width: 70%;
    min-width: 380px;
    margin: auto;
    min-height: 522px;
    height: 80%;
    background-color: wheat;
    border-radius: 22px;
    padding: 20px;
    position: relative;
  }
  .container{
    padding: 20px;
  }
}